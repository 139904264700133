export const calculateFieldSize = (fieldSize: string | undefined, hasEndAdornment?: boolean) => {
  let size;
  switch (fieldSize) {
    case 'fullWidth':
      return '100%';
    case 'xs':
      size = 72;
      break;
    case 'sm':
      size = 120;
      break;
    case 'lg':
      size = 450;
      break;
    default:
      size = 250;
  }

  if (hasEndAdornment) {
    size = size + 32;
  }

  return `${size}px`;
};

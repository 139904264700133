import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { FlsInputField } from '.';
import { INPUT_DELAY } from '../../constants/databaseConstants';

const DebouncedFlsInputField = ({ value, onChange, ...props }) => {
  const [innerValue, setInnerValue] = useState('');

  useEffect(() => {
    if (value) {
      setInnerValue(value as string);
    } else {
      setInnerValue('');
    }
  }, [value]);

  const debouncedHandleOnChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
    },
    INPUT_DELAY,
  );

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    const newValue = event.currentTarget.value;
    setInnerValue(newValue);
    debouncedHandleOnChange(event);
  }, []);

  return <FlsInputField {...props} value={innerValue} onChange={handleOnChange} />;
};

export default DebouncedFlsInputField;

import {
  Sample,
  Form,
  Lab,
  Record as FlsRecord,
  ListSampleResponse,
  SamplePayload,
  RetrievedRecord,
  WaterBulkPayload,
  ExternalTests,
  RegisterExternalTestsRequest,
  CalendarResponse,
  OrderExternalTestsRequest,
  FlsFile,
  JsonPatch,
  LatestRecordForSamplesPayload,
  LatestRecordForSample,
  PreValue,
  UpdateSampleFormRequest,
  VesselSampleInfo,
  VesselSummary,
  FinalizationRequest,
  PreValueTypes,
  ExternalLabOrderData,
} from '.';
import { HttpClient } from './useHttpClient';

export const createApiClient = (httpClient: HttpClient) => ({
  getSampleById: (id: string) => httpClient.current.get<Sample>(`v1/FlsSamples/${id}`),

  deleteSamplebyId: (id: string) => httpClient.current.delete(`v1/FlsSamples/${id}`),

  postSample: (sample: SamplePayload) => httpClient.current.post('v1/FlsSamples', sample),

  putSample: (sample: SamplePayload) => httpClient.current.put('v1/FlsSamples', sample),

  listSampleByLab: (
    labId: string,
    page: number,
    pageSize: number,
    currentPanel: 'InProgress' | 'Finalized' | 'SentToExternalLab',
    sortBy?: string | null,
    sortDirection?: string | null,
    filterValue?: string | null,
  ) =>
    httpClient.current.get<ListSampleResponse>(
      `v1/labs/${labId}/flssamples?page=${page}&pageSize=${pageSize}
      &sortBy=${sortBy}&sortDirection=${sortDirection}&filterValue=${filterValue}&currentPanel=${currentPanel}`,
    ),

  listCreateSampleOptions: () => httpClient.current.get<PreValue>('v1/FlsSamples/prevalues'),

  getSampleCountByLab: (labId: string) =>
    httpClient.current.get<number>(`v1/labs/${labId}/sample-count`),

  getFormById: (id: string) => httpClient.current.get<Form>(`v1/Forms/${id}`),

  listForms: () => httpClient.current.get<Form[]>('v1/Forms'),

  getLabById: (id: string) => httpClient.current.get<Lab>(`v1/Labs/${id}`),

  postLab: (lab: Lab) => httpClient.current.post('v1/Labs', lab),

  getRecordById: (id: string) => httpClient.current.get<RetrievedRecord>(`v1/Records/${id}`),

  getRecordsByFormId: (formId: string) =>
    httpClient.current.get<RetrievedRecord[]>(`v1/forms/${formId}/records`),

  getNewestRecordByFormId: (formId: string, sampleId: string) =>
    httpClient.current.get<RetrievedRecord>(
      `v1/flssamples/${sampleId}/forms/${formId}/records/latest`,
    ),

  getLatestRecordForSamples: (payload: LatestRecordForSamplesPayload) =>
    httpClient.current.get<LatestRecordForSample[]>(
      `v1/Records/GetLatestRecordForSamples`,
      payload,
    ),

  postRecord: (record: FlsRecord) => httpClient.current.post(`v1/Records`, record),

  postFileForParsing: (file: FormData) =>
    httpClient.current.post(`v1/Records/upload`, file, undefined, true),

  postBulkInsert: (payload: WaterBulkPayload) =>
    httpClient.current.post(`v1/Records/BulkInsert`, payload),

  listRecordsBySample: (sampleId: string) =>
    httpClient.current.get<FlsRecord[]>(`v1/Records/GetRecordsBySample?sampleId=${sampleId}`),

  listFormsByRecordsOnSample: (sampleId: string) =>
    httpClient.current.get<string[]>(`v1/Records/ListFormsByRecordsInSample?sampleId=${sampleId}`),

  searchVessels: (query: string, options?: RequestInit) =>
    httpClient.current.get<VesselSummary[]>(`v1/Vessels/search?query=${query}`, undefined, options),

  getVessel: (orderNo: string) =>
    httpClient.current.get<VesselSampleInfo>(`v1/Vessels/sample-info/${orderNo}`),

  updateSampleForms: (request: UpdateSampleFormRequest) =>
    httpClient.current.put(`v1/flssamples/${request.id}/forms`, request),

  getExternalTests: () => httpClient.current.get<ExternalTests>(`v1/flssamples/external-tests`),

  registerExternalTests: (sampleId: string, request: RegisterExternalTestsRequest) =>
    httpClient.current.put(`v1/flssamples/${sampleId}/external-tests`, request),

  getCalendar: (orderNo: string, from: string, to: string) =>
    httpClient.current.get<CalendarResponse>(`v1/calendar/${orderNo}?from=${from}&to=${to}`),

  orderExternalTests: (request: OrderExternalTestsRequest) =>
    httpClient.current.post(`v1/flssamples/external-tests/order`, request),

  previewExternalTestsOrder: (request: OrderExternalTestsRequest) =>
    httpClient.current.post<Response>(`v1/flssamples/external-tests/order/preview`, request),

  getExternalLabOrderData: (labId: string) =>
    httpClient.current.get<ExternalLabOrderData>(
      `v1/labs/${labId}/flssamples/marked-for-external-testing`,
    ),
  getDocumentationFiles: (sampleId: string) =>
    httpClient.current.get<FlsFile[]>(`v1/flssamples/${sampleId}/files/documentation`),

  getOwnerEmails: (orderNo: string) =>
    httpClient.current.get<PreValueTypes[]>(`v1/Vessels/sample-info/owner-emails/${orderNo}`),

  uploadDocumentationFile: (sampleId: string, request: FormData) =>
    httpClient.current.post(
      `v1/flssamples/${sampleId}/files/documentation`,
      request,
      undefined,
      true,
    ),

  downloadCertificatePreview: (sampleId: string) =>
    httpClient.current.downloadFile(`v1/flssamples/${sampleId}/certificate/preview`),

  postFinalization: (sampleId: string, request: FinalizationRequest) =>
    httpClient.current.post(`v1/flssamples/${sampleId}/finalize`, request),

  downloadDocumentation: (sampleId: string, filePath: string) =>
    httpClient.current.downloadFile(`v1/flssamples/${sampleId}/files/${filePath}`),

  //Any here to say that you can pass just the fields you need to update
  patchSample: (sampleId: string, fields: JsonPatch[]) =>
    httpClient.current.patch(`v1/flssamples/${sampleId}`, fields),

  deleteDocumentation: (sampleId: string, filePath: string) =>
    httpClient.current.delete(`v1/flssamples/${sampleId}/files/${filePath}`),
});

export type ApiClient = ReturnType<typeof createApiClient>;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { QrCode } from '../util';
import { LabelProps } from './LabelSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100mm',
    height: '70mm',
    backgroundColor: 'transparent',
  },
  content: {
    marginTop: '15mm',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    width: '100mm',
    height: '55mm',
    '& > *': {
      margin: '0px',
      color: 'black',
    },
  },
  qrContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40mm',
    width: '40mm',
    boxSizing: 'inherit',
  },
  header: {
    fontSize: '30px',
  },
}));

const BigLabel = (props: LabelProps, ref) => {
  const classes = useStyles();

  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.content}>
        <div>
          <p className={classes.header}>
            <strong>{props.title}</strong>
          </p>
          <p>
            <strong>{props.testProfile}</strong>
          </p>
          {props.comment && <p>{props.comment}</p>}
        </div>
        <div className={classes.qrContainer}>
          <QrCode value={props.qrContent} margin={1} scale={4} />
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(BigLabel);

import React from 'react';
import { AppBar, Toolbar, Theme, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as FramoLogo } from '../../assets/logo/framo.svg';
import { useAuthContext } from '../../contexts';
import { FramoIcon } from '../buttons';
import { IconHandler } from '../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2, 2, 0),
  },
  appBar: {
    borderRadius: '6px',
    boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.32)',
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  svgWrapper: {
    width: '92px',
  },
  toolbarContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function FlsHeader() {
  const classes = useStyles();
  const { logout } = useAuthContext();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position='static'>
        <Toolbar>
          <div className={classes.toolbarContent}>
            <Link to={'/'} className={classes.linkWrapper}>
              <FramoLogo className={classes.svgWrapper} />
            </Link>
            <FramoIcon handleClick={logout}>
              <IconHandler iconIdentifier='logout' />
            </FramoIcon>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

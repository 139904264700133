import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CalendarIcon } from '../../assets/icons';
import { FramoIcon } from '../buttons';
import { HelperText, IconHandler } from '../util';
import clsx from 'clsx';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      margin: '0px',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    margin: 0,
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5),
    },
    '& .MuiInputBase-root': {
      border: '1px solid transparent',
      borderColor: (props: DatePickerProps) =>
        props.helperText ? theme.palette.error.main : 'transparent',
      backgroundColor: (props: DatePickerProps) =>
        props.disabled ? theme.palette.button.disabled : theme.palette.background.input,
      color: theme.palette.text.primary,
      height: theme.spacing(6),
      width: '100%',
      borderRadius: (props: DatePickerProps) =>
        props.handleDisableButton ? '4px 0px 0px 4px' : '4px',
      transition: '0.2s',
      '&:focus-within': {
        color: theme.palette.text.secondary,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& .MuiButtonBase-root': {
          '& .MuiIconButton-label': {
            '& > *': {
              '& > *': {
                transition: '0.2s',
                stroke: theme.palette.text.primary,
              },
            },
          },
          '&:hover': {
            '& .MuiIconButton-label': {
              '& > *': {
                '& > *': {
                  stroke: theme.palette.button.highlight,
                },
              },
            },
          },
        },
      },
    },
    //Style Icon
    '& .MuiButtonBase-root': {
      '& .MuiIconButton-label': {
        '& > *': {
          '& > *': {
            transition: '0.2s',
            stroke: theme.palette.text.secondary,
          },
        },
      },
      '&:hover': {
        '& .MuiIconButton-label': {
          '& > *': {
            '& > *': {
              stroke: theme.palette.button.highlight,
            },
          },
        },
      },
    },
  },
  closeIcon: {
    borderRadius: '0px',
    '& > *': {
      borderRadius: '0px 4px 4px 0px',
      backgroundColor: theme.palette.background.default,
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    '&:hover': {
      '& > *': {
        '& > *': {
          '& > *': {
            stroke: theme.palette.icons.secondary,
          },
        },
      },
    },
  },
  datePickerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

interface DatePickerProps {
  id?: string;
  name?: string;
  handleDisableButton?: () => void;
  disabled?: boolean;
  value: Date | null;
  onChange: (newDate: Date | null) => void;
  helperText?: string;
  displayHelper?: boolean;
  className?: string;
}

const Datepicker = (props: DatePickerProps) => {
  const {
    id,
    value,
    handleDisableButton,
    disabled,
    helperText,
    displayHelper,
    onChange,
    name,
    className,
  } = props;
  const classes = useStyles(props);

  const onDateChange = (date: MaterialUiPickersDate) => {
    /** This resolves two issues:
     *  first: Framo does not care about the minutes - only date. This means that accidental issues can arrive with timezones and stuff happening close to midnight local time
     *  second: Manually editing datepicker input field results in 00:00 localtime, which gets parsed as yesterday by utc. Set to 12:00 to avoid any date migration
     */

    if (date?.getHours() === 0 && date?.getHours() === 0) {
      // I have no idea why this has to be done. Adding 12 hours to UTC also moves it one day back. So it has to be corrected by adding one day back
      date.setUTCDate(date.getUTCDate() + 1);
      date?.setUTCHours(12);
      date?.setUTCMinutes(0);
    } else {
      date?.setUTCHours(12);
      date?.setUTCMinutes(0);
    }
    onChange(date);
  };

  return (
    <div className={classes.errorContainer}>
      <div className={classes.datePickerContainer}>
        <KeyboardDatePicker
          autoOk
          autoComplete='off'
          id={id}
          name={name}
          className={clsx(classes.root, className)}
          disableToolbar
          disabled={disabled}
          variant='inline'
          format='dd/MM/yyyy'
          margin='normal'
          inputVariant='standard'
          invalidDateMessage={<></>} //Overide internal error handling
          maxDateMessage={<></>}
          minDateMessage={<></>}
          InputProps={{ disableUnderline: true }}
          keyboardIcon={<CalendarIcon />}
          value={value}
          onChange={(date, value) => onDateChange(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        {handleDisableButton && (
          <FramoIcon className={classes.closeIcon} handleClick={handleDisableButton}>
            <IconHandler iconIdentifier='close' />{' '}
          </FramoIcon>
        )}
      </div>
      {displayHelper && <HelperText helperText={helperText} />}
    </div>
  );
};
export default Datepicker;

import { CircularProgress, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { SelectVessel } from '.';
import { useSampleContext, useVesselContext } from '../../contexts';
import { FramoAction, FramoButton } from '../buttons';
import { isEmpty } from 'ramda';
import { FlsCard } from '../card';
import { useParams } from 'react-router-dom';
import { useApiClient } from '../../api';
import { useLoading } from '../../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    minHeight: '233px',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '&:first-child': {
        marginTop: theme.spacing(4),
      },
      '&:last-child': {
        marginBottom: theme.spacing(4),
      },
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
    '& > *': {
      height: '24px',
      '&: first-child': {
        flexBasis: 'start',
      },
    },
  },
  dividerStyle: {
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(0, 2),
  },
}));

interface VesselBoxProps {
  openModal: () => void;
  setVesselOverwrite: React.Dispatch<React.SetStateAction<boolean>>;
}

const VesselBox = (props: VesselBoxProps) => {
  const classes = useStyles();
  const { vessel, removeSelectedVessel, setVessel } = useVesselContext();
  const { sample } = useSampleContext();
  const client = useApiClient();
  const { urlSampleId } = useParams<{ urlSampleId: string }>();
  const { state, dispatch } = useLoading();

  React.useEffect(() => {
    async function getVesselOnSample() {
      if (urlSampleId && sample?.installationId) {
        dispatch({ type: 'REQUEST' });
        try {
          const sampleVessel = await client.getVessel(sample.installationId);
          setVessel(sampleVessel);
        } catch (error) {
          dispatch({
            type: 'ERROR',
            error,
            toast: 'Unable to retrieve previously registered vessel.',
          });
          console.error(error);
        }
      }
    }
    getVesselOnSample();
  }, [sample]);

  React.useEffect(() => {
    if (!isEmpty(vessel)) {
      dispatch({ type: 'SUCCESS' });
    }
  }, [vessel]);

  const changeVessel = () => {
    removeSelectedVessel();
    props.setVesselOverwrite(true);
  };

  return (
    <FlsCard className={classes.root} variant='normal'>
      <div className={classes.header}>
        <Typography variant='body2'>Ship Record</Typography>
        {!isEmpty(vessel) && (
          <FramoAction colorVariant='secondary' handleClick={changeVessel}>
            Change
          </FramoAction>
        )}
      </div>
      <Divider className={classes.dividerStyle} variant='fullWidth' />
      {state.state === 'pending' ? (
        <div className={classes.content}>
          <CircularProgress size={40} />
          <Typography variant='body2'>Retrieving registered vessel....</Typography>
        </div>
      ) : isEmpty(vessel) ? (
        <div className={classes.content}>
          <SelectVessel colorVariant={'primary'} />
        </div>
      ) : (
        <div>
          <div className={classes.content}>
            <Typography variant='body2'>{vessel.name}</Typography>
            <FramoButton type='button' buttonStyle='styleOutlined' handleClick={props.openModal}>
              Info and Calendar
            </FramoButton>
          </div>
        </div>
      )}
    </FlsCard>
  );
};

export default VesselBox;

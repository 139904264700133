import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { FramoButton } from '../../components/buttons';
import { GridToolbarColumnsButton, GridToolbarContainer } from '@material-ui/data-grid';
import { FlsInputField } from '../inputs';

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  menuContainer: {
    height: theme.spacing(9),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3),
  },
  toolbarButtons: {
    '& > *': {
      '& > *': {
        backgroundColor: theme.palette.button.normalAltBgd,
        marginLeft: theme.spacing(1),
        padding: theme.spacing(0, 2, 0, 2),
        height: theme.spacing(6),
        border: '2px solid',
        transition: '0.3s',
        borderColor: theme.palette.button.normalBgd,
        color: theme.palette.button.textAlt,
        '&:focus': {
          boxShadow: theme.palette.boxShadows.focus,
          backgroundColor: theme.palette.button.normalAltBgd,
          color: theme.palette.button.highlight,
          borderColor: theme.palette.button.highlight,
        },
        '&:hover': {
          boxShadow: theme.palette.boxShadows.dark,
          backgroundColor: theme.palette.button.highlightAlt,
          color: theme.palette.button.highlight,
          borderColor: theme.palette.button.highlight,
        },
      },
    },
  },
  search: {
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
}));

interface MenuProps {
  onSearchChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  searchQuery: string;
}

const DatagridToolbar = (props: MenuProps) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <GridToolbarContainer className={classes.menuContainer}>
      <div className={classes.toolbarButtons}>
        <GridToolbarColumnsButton />
        {/*<GridToolbarExport csvOptions={{ allColumns: true }} /> Disabled until supported properly */}
      </div>
      <div className={classes.actionContainer}>
        <FlsInputField
          autoFocus
          value={props.searchQuery}
          onChange={props.onSearchChange}
          placeholder='Search by ID or Installation...'
          className={classes.search}
        />
        <FramoButton handleClick={() => history.push('/createSample')}>Add new</FramoButton>
      </div>
    </GridToolbarContainer>
  );
};

export default DatagridToolbar;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { QrCode } from '../util';
import { LabelProps } from './LabelSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '38mm',
    height: '20mm',
    backgroundColor: 'transparent',
    marginLeft: '19mm',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    width: '38mm',
    height: '20mm',
  },
  qrContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20mm',
    width: '20mm',
    boxSizing: 'inherit',
  },
  text: {
    margin: '0px',
    color: 'black',
  },
}));

const SmallLabel = (props: LabelProps, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref} className={classes.root}>
      <div className={classes.content}>
        <p className={classes.text}>{props.title}</p>
        <div className={classes.qrContainer}>
          <QrCode value={props.qrContent} margin={1} scale={2} />
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(SmallLabel);

import { useEffect, useState } from 'react';
import { createSuperContext } from 'react-super-context';
import { Sample } from '../api';
import { useApiClient } from '../api';

const [sampleContext, useSampleContext] = createSuperContext(() => {
  const client = useApiClient();

  const [sampleId, setSampleId] = useState<string>();

  const [sample, setSample] = useState<Sample>();
  const [errors, setErrors] = useState<string>();
  const [state, setState] = useState<string>();

  async function patchSample(property: string, value: any) {
    try {
      if (sample) {
        setState('pending');
        setErrors('');
        await client.patchSample(sample?.id, [
          { op: 'replace', path: `/${property}`, value: value },
        ]);

        setSample((_) => {
          return {
            ...sample,
            [property]: value,
          };
        });
        setState('resolved');
      }
    } catch (error) {
      setErrors(error);
      setState('rejected');
      console.error(error);
    }
  }

  async function fetchSample() {
    if (!sampleId) {
      setSample(undefined);
      return;
    }
    setState('pending');
    setErrors('');
    try {
      const fetchedSample = await client.getSampleById(sampleId);
      setState('resolved');
      if (fetchedSample) {
        setSample(fetchedSample);
      }
    } catch (error) {
      setErrors(error);
      setState('rejected');
      console.error(error);
    }
  }

  const clearSample = () => {
    setSampleId(undefined);
    setSample(undefined);
  };

  useEffect(() => {
    if (!sampleId) {
      setSample(undefined);
    } else {
      fetchSample();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleId]);

  return {
    sample,
    sampleId,
    sampleLoading: state === 'pending',
    setSampleId,
    clearSample,
    fetchSample,
    state,
    errors,
    patchSample,
  };
});

export { sampleContext, useSampleContext };

import { Chip, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import { PreValueTypes, useApiClient } from '../api';
import { FramoButtonWithLoading, FramoLink } from '../components/buttons';
import { FlsAutocomplete } from '../components/inputs';
import { useSampleContext } from '../contexts';
import { useLoading } from '../hooks';
import dateFormat from '../utils/dateFormat';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    alignItems: 'center',
    '& > *': {
      padding: theme.spacing(2),
    },
    '& > :last-child': {
      padding: 0,
    },
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  failedValidation: {
    borderColor: theme.palette.error.main,
    padding: theme.spacing(2),
  },
  chipData: {
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}));

interface FinalizationProps {
  finalizedOn?: Date;
  setFinalizedOn: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

const Finalization = (props: FinalizationProps) => {
  const { finalizedOn, setFinalizedOn } = props;
  const client = useApiClient();
  const classes = useStyles();
  const { state: previewState, dispatch: previewDispatch } = useLoading();
  const { state: prevalueState, dispatch: prevalueDispatch } = useLoading();
  const { state: finalizeState, dispatch: finalizeDispatch } = useLoading();
  const [options, setOptions] = useState<PreValueTypes[]>([]);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const { sample } = useSampleContext();
  const [timeDisplay, setTimeDisplay] = useState<string>();
  const [chipdata, setChipdata] = useState<string[]>([]);
  const [fieldValue, setFieldValue] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');

  React.useEffect(() => {
    if (finalizedOn) {
      setTimeDisplay(dateFormat.formatDateTimeLong(finalizedOn));
    }
  }, [finalizedOn]);

  const ValidateEmail = (value: string) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      return true;
    }
    return false;
  };

  const handleChange = async (value: string) => {
    if (!chipdata.includes(value) && value) {
      setChipdata([...chipdata, value]);
    }
    setInputValue('');
    setFieldValue('');
  };

  const handleDelete = (value: string) => {
    console.log(value);
    setChipdata(chipdata.filter((item) => item !== value));
  };

  const previewCertificate = async () => {
    previewDispatch({ type: 'REQUEST' });
    if (sample?.id) {
      if (!downloadLinkRef || !downloadLinkRef.current) {
        return;
      }
      try {
        const certificate = await client.downloadCertificatePreview(sample.id);

        if (certificate) {
          const href = window.URL.createObjectURL(certificate);
          const a = downloadLinkRef.current;
          a.href = href;
          a.target = '_blank';
          a.click();
          a.href = '';
          previewDispatch({ type: 'SUCCESS' });
        }
      } catch (error) {
        console.error(error);
        previewDispatch({
          type: 'ERROR',
          error: error,
          toast: 'Unknown error occured when attempting to generate certificate',
        });
      }
    } else {
      previewDispatch({
        type: 'ERROR',
        toast: 'Error reading sample information',
      });
    }
  };

  const submitFinalization = async () => {
    finalizeDispatch({ type: 'REQUEST' });
    if (sample?.id) {
      for (const i in chipdata) {
        if (!ValidateEmail(chipdata[i])) {
          finalizeDispatch({
            type: 'ERROR',
            toast: 'Invalid mail in list:"' + chipdata[i] + '". Submission aborted.',
          });
          return;
        }
      }
      try {
        await client.postFinalization(sample.id, {
          certificateReceivers: chipdata,
        });
        finalizeDispatch({ type: 'SUCCESS' });
        setTimeDisplay(dateFormat.formatDateTimeLong(new Date()));
        setFinalizedOn(new Date());
        window.location.reload();
      } catch (error) {
        console.error(error);
        finalizeDispatch({
          type: 'ERROR',
          error: error,
          toast: 'Unknown error occured when attempting to generate certificate',
        });
      }
    } else {
      finalizeDispatch({
        type: 'ERROR',
        toast: 'Error reading sample information',
      });
    }
  };

  const retrievePrevalues = async (orderNo: string) => {
    try {
      const retrievedMails = await client.getOwnerEmails(orderNo);
      console.log('Retrieved mails:', retrievedMails); //For live testing
      prevalueDispatch({ type: 'SUCCESS' });
      setOptions(retrievedMails);
      setFinalizedOn(new Date());
    } catch (error) {
      console.error(error);
      prevalueDispatch({
        type: 'ERROR',
        error: error,
        toast: 'Unknown error occured when attempting to retrieve e-mails',
      });
    }
  };

  React.useEffect(() => {
    if (sample?.installationId) {
      retrievePrevalues(sample.installationId);
    }
  }, [sample]);

  return (
    <div className={classes.root}>
      {timeDisplay && <Typography variant='body2'>Latest finalize: {timeDisplay}</Typography>}
      <div className={classes.chipContainer}>
        {chipdata &&
          Object.entries(chipdata).map(([index, value]) => {
            return (
              <Chip
                className={ValidateEmail(value) ? classes.chipData : classes.failedValidation}
                variant='outlined'
                key={index}
                label={value}
                size='small'
                onDelete={() => handleDelete(value as string)}
              />
            );
          })}
      </div>
      <FlsAutocomplete
        placeholder='Add recipient addresses...'
        autoFocus
        inputValue={inputValue}
        value={fieldValue}
        loading={prevalueState.state === 'pending'}
        onInputChange={(e, value) => setInputValue(value as string)}
        fullWidth
        allowCustomInputs
        colorVariant='primary'
        autoSelect
        options={options.map((prevalue) => prevalue.value)}
        onChange={(e, value) => handleChange(value as string)}
      />
      <div className={classes.buttonContainer}>
        <FramoButtonWithLoading
          submissionState={previewState.state}
          handleClick={previewCertificate}
        >
          Preview
        </FramoButtonWithLoading>
        <FramoLink
          location={`http://webapp/omp/ProArc/Oilmon.aspx${
            sample?.ompDocRno ? '?DocRno=' + sample.ompDocRno : ''
          }`}
        >
          OMP Sample
        </FramoLink>
        <FramoLink
          location={`http://webapp/omp/Start.aspx${
            sample?.ompDocRno ? '?DocRno=' + sample.installationId : ''
          }`}
        >
          OMP Distribute
        </FramoLink>
        <FramoButtonWithLoading
          disabled={finalizeState.state === 'pending'}
          buttonStyle='styleOutlined'
          submissionState={finalizeState.state}
          handleClick={submitFinalization}
        >
          Finalize
        </FramoButtonWithLoading>
      </div>
      <a ref={downloadLinkRef} tabIndex={-1} />
    </div>
  );
};

export default Finalization;

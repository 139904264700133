import { Severity } from '../models';

/* eslint-disable no-unused-vars */
export interface Sample {
  id: string;
  shortId: string;
  externalId: string;
  labId: string;
  createdOn: Date;
  createdBy: string;
  updatedOn: Date;
  updatedBy: string;
  ompDocNr: string;
  ompDocRno: number;
  expeditionDate: Date;
  disposalDate: Date;
  assumedSampleDate: Date;
  customerReference?: string;
  framoReference?: string;
  samplePurpose: string;
  sampledBy: string;
  samplePoint: string;
  sampleDate: Date;
  originalOilType: string;
  oilType: string;
  lastFilterChange: Date;
  sampleBottleType: boolean;
  imoNumber?: string;
  installationName?: string;
  installationId?: string;
  customer?: string;
  owner?: string;
  manager?: string;
  framoSalesOrderNumber?: string;
  partOfOmp: boolean;
  internalInvoiceOffice: string;
  internalInvoiceType: string;
  projectEngineer: string;
  hpuSystem: string;
  analysedBy: string;
  invalidSample: boolean;
  state: string;
  finalizedOn?: Date;
  isFlaggedForExternalTesting: boolean;
  isSentToExternalLab: boolean;
  sampleForm: any;
  record: Record[] | null;
  forms: SampleForm[];
  externalLabSample?: ExternalLabSample;
  microscope: boolean;
}

export interface ExternalLabSample {
  externalLabTests: string[];
  purchaseOrderNumber?: string;
  createdOn: string;
  comment?: string;
  orderedOn?: Date;
}

export interface SamplePayload {
  id?: string;
  shortId?: string;
  externalId?: string;
  labId: string;
  ompDocNr: string | null;
  ompDocRno: number | null;
  expeditionDate: Date | null;
  customerReference: string | null;
  framoReference: string | null;
  samplePurpose: string | null;
  sampledBy: string | null;
  samplePoint: string | null;
  sampleDate: Date | null;
  originalOilType: string | null;
  oilType: string | null;
  lastFilterChange: Date | null;
  sampleBottleType: boolean | null;
  imoNumber: string | null;
  installationName: string | null;
  installationId: string | null;
  owner: string | null;
  manager: string | null;
  customer: string | null;
  framoSalesOrderNumber: string | null;
  internalInvoiceOffice: string | null;
  internalInvoiceType: string | null;
  projectEngineer: string | null;
  hpuSystem: string | null;
  analysedBy: string | null;
  invalidSample: boolean;
  state: string;
  flaggedForExternalTesting: boolean;
  forms: SampleFormPayload[];
}

export interface SampleFormPayload {
  id: string;
}

export interface PreValue {
  values: {
    analysedBy: PreValueTypes[];
    customer: PreValueTypes[];
    hpuSystem: PreValueTypes[];
    invoiceOffice: PreValueTypes[];
    invoiceType: PreValueTypes[];
    oilType: PreValueTypes[];
    projectEngineer: PreValueTypes[];
    samplePurpose: PreValueTypes[];
    samplePoint: PreValueTypes[];
    sampledBy: PreValueTypes[];
  };
}

export interface PreValueTypes {
  label: string;
  value: string;
}

export interface ListSamplePayload {
  labid: string;
  pageNumber: number;
  pageSize: number;
}

export interface ListSampleResponse {
  page: number;
  results: Sample[];
  totalPages: number;
}

export interface Form {
  id: string;
  analysisType: string;
  defaultReplicates?: number;
  isMandatory: boolean;
  formGroups: FormGroup[];
  sortOrder: number;
}

export interface SampleForm {
  id: string;
  analysisType: string;
  isMandatory: boolean;
  status: FormStatus;
  shortName: string;
  showStatus: boolean;
  sortOrder: number;
}

export enum ProcessStatusEnum {
  InProgress,
  SentToExternalTest,
  Finalized,
}
export type ProcessStatus = keyof typeof ProcessStatusEnum;

export enum FormStatusEnum {
  Empty,
  Normal,
  Critical,
  Marginal,
}
export type FormStatus = keyof typeof FormStatusEnum;

export interface FormGroup {
  id: string;
  name: string;
  sortOrder: number;
  hidden: boolean;
  closeable: boolean;
  formFields: FormField[];
}

export interface FormField {
  id: string;
  label: string;
  submitToOmp: boolean;
  validationRules: FormFieldValidationRule[];
  preValues: FormFieldPreValues[];
  classifiers: FormFieldClassifier[];
  formFieldType: FormFieldType;
  observers: FormFieldObserver[];
  isClassifierSelector: boolean;
  computed: boolean;
}

export interface FinalizationRequest {
  certificateReceivers: string[];
}

export interface FormFieldObserver {
  id: string;
  observerFieldId: string;
}

export interface FormFieldDataType {
  id: string;
  type: string;
  discriminator: string;
}

export interface FormFieldInputType {
  id: string;
  type: string;
  alias: string;
}

export interface FormFieldType {
  id: string;
  formFieldDataType: FormFieldDataType;
  formFieldInputType: FormFieldInputType;
  fieldSize: 'xs' | 'sm' | 'md' | 'lg' | 'fullWidth';
  suffix: string;
  icon: string;
  showPlaceholder: boolean;
}

export interface FormFieldValidationRule {
  id: string;
  type: string;
  value?: number;
  regex: string;
  errorText: string;
  severity: Severity;
  showNotification: boolean;
  classifierId?: string;
}

export interface FormFieldPreValues {
  id: string;
  value: string;
  label?: string;
  sortOrder?: string;
}

export interface FormFieldClassifier {
  classifierId: string;
  formfieldId: string;
  classifier: Classifier;
}

export interface Classifier {
  id: string;
  name: string;
  comparisonType: string;
  classThresholds: ClassThreshold[];
}

export interface ClassThreshold {
  id: string;
  firstCompareValue: string;
  secondCompareValue: string;
  replaceValue: string;
}

export interface Lab {
  id: string;
  labname: string;
  labCode: string;
  shortLabCode: string;
}

export interface LatestRecordForSamplesPayload {
  flsSampleIds: string[];
  formFieldId: string;
}

export interface LatestRecordForSample {
  flsSampleId: string;
  value: any;
}
export interface Record {
  id: string;
  formId: string;
  flsSampleId: string;
  recordValues: RecordValue[];
}

export interface RecordValue {
  Discriminator: string;
  FormFieldId: string;
  DecimalValue?: number;
  IntValue?: number;
  StringValue?: string;
  LongStringValue?: string;
  RetrievedValue: boolean;
}

export interface RecordPayload {
  formId: string;
  flsSampleId: string;
  recordValues: RecordValue[];
}

export interface WaterBulkPayload {
  formId: string;
  records: RecordPayload[];
}

export interface RecordValueString extends RecordValue {
  StringValue: string;
}

export interface RecordValueDecimal extends RecordValue {
  DecimalValue?: number;
}

export interface RecordValueInteger extends RecordValue {
  IntValue?: number;
}

export interface RecordValueLongString extends RecordValue {
  LongStringValue: string;
}

export interface RetrievedRecord {
  id: string;
  formId: string;
  flsSampleId: string;
  recordValues: RetrievedRecordValue[];
}

export interface RetrievedRecordValue {
  discriminator: string;
  formFieldId: string;
}

export interface RetrievedRecordValueString extends RetrievedRecordValue {
  stringValue: string;
}

export interface RetrievedRecordValueDecimal extends RetrievedRecordValue {
  decimalValue?: number;
}

export interface RetrievedRecordValueDecimal extends RetrievedRecordValue {
  intValue?: number;
}

export interface RetrievedRecordValueLongString extends RetrievedRecordValue {
  longStringValue: string;
}

export interface Vessel {
  id: string;
  vesselName: string;
  originalOilType: string;
  owner: string;
  manager: string;
  customer: string;
  installCode: string;
  salesOrderno: string;
}

export interface VesselSummary {
  orderNo: string;
  name: string;
  ownerCode: number;
}

export interface VesselSampleInfo extends VesselSummary {
  owner: string;
  manager: string;
  partOfOmp: boolean;
  originalOilType: string;
  oilType: string;
  lastFilterChange: Date | null;
  imo: string;
  customer: string;
  salesOrder: string;
  internalInvoiceOffice: string;
}

export interface VesselListObject {
  id: string;
  vesselName: string;
}

export interface UpdateSampleFormRequest {
  id: string;
  forms: string[];
}

export interface ExternalTests {
  standard: PreValueTypes[];
  chlorides: PreValueTypes[];
  all: PreValueTypes[];
}

export interface RegisterExternalTestsRequest {
  sampleId: string;
  analysisTypes: string[];
  comment?: string;
}

export interface CalendarResponse {
  orderNo: string;
  entries: CalendarItem[];
}

export interface CalendarItem {
  name: string;
  shortName: string;
  sampleType: string;
  docRno?: number;
  date: Date;
  analysedBy: string;
  sampleBy: string;
  sampleStatus: CalendarSampleStatus;
  reportSentToCustomer: boolean;
  overDue: boolean;
  overDueDate?: Date;
  status: CalendarEntryStatusEnum;
}

export type CalendarSampleStatus = FormStatus;

export enum CalendarEntryStatusEnum {
  Planned = 'Planned',
  Moved = 'Moved',
  Ongoing = 'Ongoing',
  Completed = 'Completed',
  OngoingReport = 'OngoingReport',
}

export interface SampleForExternalTesting {
  sampleId: string;
  shortId: string;
  analysisTypes: string[];
  analysisGroups: string[];
}

export interface ExternalLabOrderData {
  samples: SampleForExternalTesting[];
  poNumber?: string;
}

export interface OrderExternalTestsRequest {
  labId: string;
  samples: string[];
  purchaseOrderNumber: string;
  comment: string;
}

export interface ResponseWithAccessKey<T> {
  data: T;
  accessKey: string;
}

export interface FlsFile {
  blobPath: string;
  contentLength?: number;
  createdOn: Date;
  updatedOn: Date;
  contentType: string;
  fileName?: string;
  fileExtention?: string;
}

export interface FileUploadRequest {
  file: File;
  fileName: string;
  sampleId: string;
}

export interface JsonPatch {
  op: 'add' | 'remove' | 'replace' | 'copy' | 'move' | 'test';
  path: string;
  value?: unknown;
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import { GridCellParams } from '@material-ui/data-grid';
import { Divider, makeStyles } from '@material-ui/core';
import { FramoAction } from '../../../components/buttons';
import { useModalContext, useSampleContext } from '../../../contexts';
import { Sample } from '../../../api';
import SampleLinks from '../../../modals/SampleLinks';

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    margin: theme.spacing(1),
  },
}));

const RowNavigationButtons = (params: GridCellParams) => {
  const classes = useStyles();
  const history = useHistory();
  const { setContent, setIsOpen, setModalProps } = useModalContext();
  const { setSampleId } = useSampleContext();

  const handleNavigation = () => {
    setIsOpen(false);
    setSampleId(params.row.id);
    history.push(`/createSample/${params.row.id}`);
  };

  const handleLinkModal = () => {
    setModalProps({
      title: 'External Links For ' + params.row.shortId,
      titleIcon: undefined,
    });
    setContent(<SampleLinks selectedSample={params.row as Sample} />);
    setIsOpen(true);
  };
  return (
    <div className={classes.actionButtons}>
      <FramoAction colorVariant='secondary' handleClick={handleNavigation}>
        Info
      </FramoAction>
      <Divider className={classes.divider} orientation='vertical' flexItem />
      <FramoAction colorVariant='secondary' handleClick={handleLinkModal}>
        Links
      </FramoAction>
      <Divider className={classes.divider} orientation='vertical' flexItem />
      <FramoAction
        colorVariant='secondary'
        handleClick={() => history.push(`/analyses/${params.row.id}`)}
      >
        Results
      </FramoAction>
    </div>
  );
};

export default RowNavigationButtons;

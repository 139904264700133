import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
    textTransform: (props: FlsLabelProps) => (props.textTransform as any) || 'uppercase',
    fontSize: '0.75rem',
  },
  labelContainer: {
    //width: (props: FlsLabelProps) => calculateFieldSize(props.fieldSize),
    display: 'flex',
    paddingBottom: theme.spacing(0.5),
  },
}));

interface FlsLabelProps extends React.HTMLProps<HTMLLabelElement> {
  fieldSize?: string;
  textTransform?: string;
}

const FlsLabel = (props: PropsWithChildren<FlsLabelProps>) => {
  const { children, fieldSize, ...rest } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.labelContainer}>
      <label className={classes.label} {...rest}>
        {children}
      </label>
    </div>
  );
};

export default FlsLabel;

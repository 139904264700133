import { makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useApiClient } from '../api';
import { FramoUploadButton, FramoButtonWithLoading } from '../components/buttons';
import { FlsAutocomplete } from '../components/inputs';
import { HelperText } from '../components/util';
import { useSampleContext } from '../contexts';
import { useLoading } from '../hooks';
import { constructFormData } from '../utils/uploadUtil';

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  input: {
    display: 'none',
  },
  fileName: {
    display: 'block',
    fontWeight: 400,
  },
  uploadButtonWrapper: {
    marginBottom: theme.spacing(2),
  },
  submitWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  name: {
    marginBottom: theme.spacing(4),
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface UploadModalProps {
  forms: string[];
  existingFileNames: string[];
}

export default function UploadDocumentationModal(props: UploadModalProps) {
  const [selected, setSelected] = useState('');
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<File | undefined>(undefined);
  const [formError, setFormError] = useState('');
  const client = useApiClient();
  const { sample, fetchSample } = useSampleContext();
  const { state: uploadFormState, dispatch } = useLoading();

  const classes = useStyles();

  useEffect(() => {
    setFileName(selected);
  }, [props.existingFileNames, selected]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setFile((prev) => (files && files.length > 0 ? files[0] : prev));
  };

  const submit = async () => {
    if (!sample) {
      setFormError('No sample found');
      return;
    }
    if (!file) {
      setFormError('No file was selected');
      return;
    }
    setFormError('');
    dispatch({ type: 'REQUEST' });
    const processedFiles = constructFormData({
      files: [file],
      fileName: fileName ? fileName : file?.name,
      sampleId: sample.id,
    });
    try {
      await client.uploadDocumentationFile(sample.id, processedFiles);
      setFile(undefined);
      setSelected('');
      dispatch({ type: 'SUCCESS' });
      await fetchSample();
    } catch (error) {
      console.log(error);
      dispatch({ type: 'ERROR', error });
      setFormError('Unable to upload file');
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.uploadButtonWrapper}>
        <FramoUploadButton
          buttonStyle='styleOutlined'
          handleChange={handleChange}
          files={file ? [file] : []}
          fileAccept='image/*,.pdf,.doc,.tsv,.csv,.xlsx'
        >
          Select File
        </FramoUploadButton>
      </div>
      <FlsAutocomplete
        allowCustomInputs={true}
        placeholder='Override filename..'
        colorVariant='primary'
        value={selected}
        options={props.forms}
        onChange={(e, value: string) => {
          setSelected(value);
        }}
        autoSelect
        blurOnSelect
        cancelOption={() => setSelected('')}
        className={classes.name}
      />
      <div className={classes.submitWrapper}>
        <FramoButtonWithLoading submissionState={uploadFormState.state} handleClick={submit}>
          Upload File
        </FramoButtonWithLoading>
        <HelperText helperText={formError} />
      </div>
    </div>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ImportantIcon } from '../../assets/icons';

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    height: '20px',
    width: '20px',
  },
  errorIconBackground: {
    alignItems: 'center',
    background: theme.palette.error.main,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '20px',
    marginRight: theme.spacing(1),
    width: '20px',
  },
  errorContainer: {
    boxSizing: 'content-box',
    alignItems: 'center',
    border: 'none',
    display: 'flex',
    visibility: (props: HelperTextProps) => (props.helperText ? 'visible' : 'hidden'),
    minHeight: theme.spacing(3),
    padding: theme.spacing(1, 1, 0, 0),
  },
  helperText: {
    color: theme.palette.text.primary,
    ...theme.typography.body1,
  },
}));

interface HelperTextProps {
  helperText?: string | false;
  id?: string;
}

const HelperText = (props: HelperTextProps) => {
  const { helperText, id } = props;
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.errorContainer}>
        <div className={classes.errorIconBackground}>
          <ImportantIcon className={classes.errorIcon} />
        </div>
        <span id={`${id}-text`} className={classes.helperText}>
          {helperText ? helperText : ' '}
        </span>
      </div>
    </>
  );
};

export default HelperText;

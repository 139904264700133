import React from 'react';
import { HelperText, FlsLabel } from '../util';
import { FlsInputField } from '.';
import { FlsInputProps } from './FlsInputField';
import { Severity } from '../../models';

interface FlsTextFieldProps extends FlsInputProps {
  helperText?: string | false;
  displayHelper?: boolean;
  errorSeverity?: Severity;
}

const FlsTextField = ({
  helperText,
  displayHelper,
  errorSeverity,
  ...props
}: FlsTextFieldProps) => {
  return (
    <>
      <FlsLabel htmlFor={props.id}>{props.placeholder}</FlsLabel>
      <FlsInputField
        severity={errorSeverity ? errorSeverity : helperText ? 'Error' : 'Normal'}
        error={!!helperText}
        {...props}
      />
      {displayHelper && <HelperText id={props.id} helperText={helperText} />}
    </>
  );
};

export default FlsTextField;

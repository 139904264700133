import React, { PropsWithChildren, useState } from 'react';
import { MenuProps, Select, makeStyles } from '@material-ui/core';
import { ArrowIcon } from '../../assets/icons';
import { FieldSize } from '../../models/components';
import { calculateFieldSize } from '../../utils/inputfieldUtils';
import { HelperText } from '../util';

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: StylingProps) =>
      props.fullWidth ? '100%' : `calc(${calculateFieldSize(props.fieldSize)} - 24px)`,
    '&&': {
      padding: (props: StylingProps) =>
        props.size === 'small' ? theme.spacing(1, 1.5) : theme.spacing(1.5, 1.5),
    },
  },
  icon: {
    top: 'calc(50% - 12px)',
    color: '#fff',
    right: 5,
    position: 'absolute',
    pointerEvents: 'none',
  },
  select: {
    ...theme.typography.body1,
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.background.default,
    borderRadius: (props: StylingProps) =>
      props.open ? theme.spacing(0.75, 0.75, 0, 0) : theme.spacing(0.75),
    color: theme.palette.text.secondary,
    display: 'flex',
    transition: 'border-radius 0.25s',
    '&:focus': {
      borderRadius: theme.spacing(0.75),
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiSelect-select': {
      padding: 0,
    },
  },

  dropDownContainer: {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[0],
    borderRadius: (props: StylingProps) =>
      props.open ? theme.spacing(0, 0, 0.75, 0.75) : theme.spacing(0.75),
    transition: 'border-radius 0.25s',
    '& .MuiList-padding': {
      padding: 0,
    },
  },
  dropDownListItem: {
    color: theme.palette.text.secondary,
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiListItem-button:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiMenuItem-root': {
      borderTop: '1px solid black',
      margin: theme.spacing(0, 1),
      padding: 0,
      height: (props: StylingProps) =>
        props.size === 'small' ? theme.spacing(5) : theme.spacing(6),
    },
  },
}));

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
} as Partial<MenuProps>;

interface StylingProps extends FieldSize {
  fullWidth?: boolean;
  open: boolean;
  size?: 'small' | 'normal';
}

interface FlsSelectProps extends FieldSize {
  id?: string;
  size?: 'small' | 'normal';
  onChange: (e: React.ChangeEvent<any>) => void;
  value: any;
  fullWidth?: boolean;
  defaultValue?: any;
  helperText?: string | false;
}

/**
 * Component used to display options to the user. The children of this component should be "MenuItem" from
 * Material-ui. For this component to work properly, control the values with a value attribute and onChange
 * handler.
 */

const FlsSelect = (props: PropsWithChildren<FlsSelectProps>) => {
  const { id, size, onChange, value, fieldSize, fullWidth, defaultValue, helperText, children } =
    props;
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open, size, fieldSize, fullWidth });

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Select
        defaultValue={defaultValue}
        id={id}
        classes={{ root: classes.root, select: classes.select }}
        fullWidth={fieldSize === 'fullWidth' || fullWidth}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={value}
        onChange={onChange}
        MenuProps={{
          ...menuProps,
          classes: { paper: classes.dropDownContainer, list: classes.dropDownListItem },
        }}
        disableUnderline
        IconComponent={() => <ArrowIcon className={classes.icon} />}
      >
        {children}
      </Select>
      <HelperText id={props.id} helperText={helperText} />
    </>
  );
};

export default FlsSelect;

import React, { useEffect, useMemo, useState } from 'react';
import { FlsAutocomplete } from '../inputs';
import { useApiClient, VesselSummary } from '../../api';
import { debounce } from 'throttle-debounce';
import { useVesselContext } from '../../contexts';
import { FieldSize } from '../../models/components';
import { isEmpty } from 'ramda';

/**
 * Component which allow the user to select a vessel. The selected vessel are placed in
 * the vesselContext and can be used by other components that subscribe to that context.
 */
interface SelectVesselProps extends FieldSize {
  colorVariant?: 'primary' | 'secondary';
}

const SelectVessel = (props: SelectVesselProps) => {
  const { colorVariant, fieldSize } = props;
  const { vessel, setVessel, removeSelectedVessel } = useVesselContext();

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState<VesselSummary[]>([]);
  const [loading, setLoading] = useState(false);

  const client = useApiClient();

  const setQueryHandler = (ev, value) => {
    setQuery(value);
  };
  const debouncedChangeHandler = useMemo(() => debounce(500, setQueryHandler), []);

  useEffect(() => {
    //TODO: Make this more reusable.
    const controller = new AbortController();
    const { signal } = controller;
    if (query && query.length >= 3) {
      (async () => {
        setLoading(true);
        const vessels = await client.searchVessels(query, { signal });
        setLoading(false);
        setOptions(vessels);
      })();
    }
    return () => {
      controller.abort();
    };
  }, [query, client]);

  const handleSelect = (vessel: VesselSummary) => {
    setLoading(true);
    if (!isEmpty(vessel)) {
      setVessel(vessel);
    }
  };

  React.useEffect(() => {
    if (!isEmpty(vessel)) {
      setLoading(false);
    }
  }, [vessel]);

  return (
    <FlsAutocomplete
      autoFocus={true}
      colorVariant={colorVariant}
      options={options}
      loading={loading}
      blurOnSelect
      placeholder='Search OMP for vessel...'
      cancelOption={() => removeSelectedVessel()}
      value={vessel}
      onChange={(e, vessel: VesselSummary) => handleSelect(vessel)}
      getOptionLabel={(data: VesselSummary) => data.name || ''}
      getOptionSelected={(option: VesselSummary) => option.name === vessel?.name}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      fieldSize={fieldSize}
      selectOnFocus={false}
      onInputChange={debouncedChangeHandler}
    />
  );
};

export default SelectVessel;

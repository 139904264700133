import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import Field from './Field';
import { RecordValue, Record, FormGroup, FormField, RetrievedRecord } from '../../../api';
import {
  classifyInputValue,
  createRecord,
  findFormField,
  getValueFromRecord,
  getValueFromRetrievedRecord,
} from '../../../utils/formUtils';
import { isEmpty } from 'ramda';
import { VisibilityOffOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  formGroup: {
    display: (props: FormGroupStyleProps) => (props.hidden ? 'none' : 'flex'),
    flexWrap: 'nowrap',
    visibility: (props: FormGroupStyleProps) => (props.hidden ? 'hidden' : 'visible'),
    marginBottom: theme.spacing(1),
  },
  closeicon: {
    display: 'block',
    paddingTop: '16px',
    '& > *': {
      '&:hover': {
        color: theme.palette.button.highlight,
      },
    },
  },
}));

interface FormGroupStyleProps {
  hidden?: boolean;
}

interface FormGroupProps {
  formGroup: FormGroup;
  formId: string;
  sampleId: string;
  classifyWith: string;
  currentClassifierId?: string;
  setClassifyWith: React.Dispatch<React.SetStateAction<string>>;
  setRecords: React.Dispatch<React.SetStateAction<Record[]>>;
  retrievedRecord: RetrievedRecord | undefined;
  changeGroupVisibility: (groupName: string, hide?: boolean) => void;
  hidden?: boolean;
  closeable?: boolean;
}

const Group = ({
  formGroup,
  formId,
  sampleId,
  setRecords,
  currentClassifierId,
  classifyWith,
  setClassifyWith,
  retrievedRecord,
  changeGroupVisibility,
  hidden,
  closeable,
}: FormGroupProps) => {
  const [recordValues, setRecordValues] = useState<RecordValue[]>([]);
  //If a field is dirty the validation need to be checked. The whole formgroup is revalidated
  const [isDirty, setIsDirty] = useState(false);
  const classes = useStyles({ hidden });
  const currentClassifier = useRef<string>('');
  const initialValuesSet = useRef<boolean>(false);

  /**
   * Handles changes in a field by updating the fields current record and notifying any observers of the change.
   * The value sent to a observer is first classified if any active classifiers are accossiated with the observer field.
   * If a field is defined as a classifierSelector. This function changes the active classifier for the whole form.
   */
  const fieldChanged = useCallback(
    (discrimintator: string, formField: FormField, value: any, existingValue: boolean) => {
      const newRecord = createRecord(discrimintator, formField.id, value, existingValue);

      //Broadcasts the new value to all observers
      if (formField.observers.length > 0) {
        formField.observers.forEach((observer) => {
          const observerField = findFormField(observer.observerFieldId, formGroup);
          if (!observerField) return;
          const classifiedValue = classifyInputValue(observerField, value, classifyWith);
          fieldChanged(
            observerField.formFieldType.formFieldDataType.discriminator,
            observerField,
            classifiedValue,
            existingValue,
          );
        });
      }

      // A form may contain a field which decides which classifier a all other formfield shall use.
      if (formField.isClassifierSelector) {
        setClassifyWith(value);
      }

      setRecordValues((currentValues) => {
        if (!newRecord) {
          return currentValues;
        }
        const debug = Object.assign([], {
          ...currentValues,
          [formField.id]: newRecord,
        });
        return debug;
      });
    },
    [formGroup, classifyWith, setClassifyWith],
  );

  /**
   * Hides the form group, remove any entries, and removes the record from submission
   */
  const closeGroupAndRemoveRecord = () => {
    changeGroupVisibility(formGroup.name, true);
    setRecordValues([]);
    setRecords((records) => {
      delete records[formGroup.id];
      return records;
    });
    setIsDirty(true);
  };

  /**
   * If a formfield has any previous records associated with it, the initial value is set to the last recordvalue.
   * If the field is suppose to be hidden, this effect will reveal the field.
   */
  useEffect(() => {
    if (retrievedRecord && !isEmpty(retrievedRecord) && !initialValuesSet.current) {
      retrievedRecord.recordValues.forEach((recordValue) => {
        const formField = findFormField(recordValue.formFieldId, formGroup);
        if (!formField) return;
        if (hidden) {
          changeGroupVisibility(formGroup.name, false);
        }
        const value = getValueFromRetrievedRecord(recordValue);
        fieldChanged(recordValue.discriminator, formField, value, true);
      });
      initialValuesSet.current = true;
    }
  }, [retrievedRecord, fieldChanged, formGroup, changeGroupVisibility, hidden]);

  /**
   * If the value used of the classifier changes, all fields must be updated.
   */
  useEffect(() => {
    if (currentClassifier.current !== classifyWith) {
      Object.entries(recordValues).forEach(([_, recordValue]) => {
        const value = getValueFromRecord(recordValue);
        const formfield = findFormField(recordValue.FormFieldId, formGroup);
        if (!formfield) return;
        fieldChanged(recordValue.Discriminator, formfield, value, recordValue.RetrievedValue);
      });
      currentClassifier.current = classifyWith;
    }
  }, [classifyWith, fieldChanged, recordValues, formGroup]);

  /**
   * Updates the records which are submitted in the analysesPage. This is done without making
   * a copy of the array to avoid unecessary rerenders.
   */
  useEffect(() => {
    setRecords((records) => {
      records[formGroup.id] = {
        formId,
        flsSampleId: sampleId,
        recordValues: Object.values(recordValues),
      };
      return records;
    });
  }, [recordValues, formId, setRecords, sampleId, formGroup.id]);

  return (
    <Box className={classes.formGroup}>
      {formGroup.formFields.map((formField) => (
        <Field
          key={formField.id}
          value={recordValues[formField.id]}
          formField={formField}
          fieldChanged={fieldChanged}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          currentClassifierId={currentClassifierId}
        />
      ))}
      {closeable && (
        <div className={classes.closeicon}>
          <IconButton onClick={() => closeGroupAndRemoveRecord()} tabIndex={-1}>
            <VisibilityOffOutlined />
          </IconButton>
        </div>
      )}
    </Box>
  );
};

export default Group;

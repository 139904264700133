import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AnalysesPage, CreateSample, Dashboard } from './pages';
import NotFound from './NotFound';
import { makeStyles } from '@material-ui/core/styles';
import { FlsHeader } from './components/header';
import FlsToast from './components/util/FlsToast';
import 'react-toastify/dist/ReactToastify.css';
const useStyles = makeStyles({
  mainLayout: {
    width: '100%',
    '@media screen': {},
    '@media print': {
      display: 'block',
      overflow: 'visible',
    },
  },
});
const Routing = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainLayout}>
      <FlsHeader />
      <Switch>
        <Route path={'/createSample/:urlSampleId'} component={CreateSample} />
        <Route path={'/createSample'} component={CreateSample} />
        <Route path={'/analyses/:urlSampleId'} component={AnalysesPage} />
        <Route path={'/analyses'} component={AnalysesPage} />
        <Route path={'/'} component={Dashboard} />
        <Route component={NotFound} />
      </Switch>
      <FlsToast
        position='bottom-right'
        autoClose={5000}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        hideProgressBar
        draggable={false}
      />
    </div>
  );
};

export default Routing;

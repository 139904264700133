import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { FieldSize } from '../../models/components';
import { FlsInputField } from '../inputs';
import { HelperText, IconHandler } from '../util';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'inherit',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cancelIcon: {
    '& > *': {
      fill: (props: StylingProps) =>
        props.colorVariant === 'secondary'
          ? theme.palette.background.paper
          : theme.palette.background.default,
    },
  },
  icon: {
    top: 'calc(50% - 12px)',
    color: '#fff',
    right: 5,
    position: 'absolute',
    pointerEvents: 'none',
  },
  fieldContainer: {
    //Defaults to md if no size is set. No maxWidht when fullWidth is set
    width: (props: StylingProps) =>
      props.fullWidth || props.fieldSize === 'fullWidth'
        ? '100%'
        : props.fieldSize === 'xs'
        ? '75px'
        : props.fieldSize === 'sm'
        ? '125px'
        : props.fieldSize === 'lg'
        ? '450px'
        : '250px',
  },
  option: {
    borderTop: '1px solid black',
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 0),
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: (props: StylingProps) =>
        props.colorVariant === 'secondary'
          ? theme.palette.background.paper
          : theme.palette.background.default,
    },
    '&:focus': {
      color: theme.palette.primary.main,
      backgroundColor: 'inherit',
    },
    '&[aria-selected="true"]': {
      color: theme.palette.primary.main,
      backgroundColor: 'inherit',
    },
  },
  dropDownContainer: {
    margin: theme.spacing(0),
    borderRadius: theme.spacing(0, 0, 0.75, 0.75),
    backgroundColor: (props: StylingProps) =>
      props.colorVariant === 'secondary'
        ? theme.palette.background.paper
        : theme.palette.background.default,
  },
  override: {
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface StylingProps {
  open: boolean;
  fieldSize?: string;
  fullWidth?: boolean;
  colorVariant?: 'primary' | 'secondary';
}

interface FlsAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    FieldSize {
  cancelOption?: () => void;
  colorVariant?: 'primary' | 'secondary';
  allowCustomInputs?: boolean;
  helperText?: string | false;
  displayHelper?: boolean;
  autoFocus?: boolean;
}

/**
 * Autocomplete component that takes a list of options. The params listed below are not exhaustive, but should be considered when
 * using the component. For example controlled input is necessary to ensure that the custom features are working
 *
 * @param cancelOption Defines what happens when a user removes the selected option. Usually the current value is set to null
 * @param value The value selected by the user
 * @param onChange Sets the value
 * @param inputValue The value displayed in the textbox
 * @param onInputChange Sets the value displayed in the textbox
 * @param getOptionLabel is used to show the text in the dropdown. Necessary if object
 * @param getOptionSelected this is usde to determine the selected value of given array. Necessary if object
 */
function FlsAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  cancelOption,
  placeholder,
  loading,
  fieldSize,
  colorVariant,
  allowCustomInputs,
  helperText,
  displayHelper,
  autoFocus,
  ...props
}: Omit<FlsAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const [open, setOpen] = useState(false);
  const classes = useStyles({
    open,
    fieldSize,
    colorVariant,
    fullWidth: props.fullWidth,
  });
  return (
    <>
      <Autocomplete
        freeSolo={allowCustomInputs}
        loading={loading}
        value={props.value}
        {...props}
        classes={{
          root: classes.fieldContainer,
          paper: classes.dropDownContainer,
          option: classes.option,
        }}
        open={open}
        blurOnSelect
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={({ InputProps: { ref }, InputLabelProps, ...rest }) => (
          <div ref={ref}>
            <FlsInputField
              className={classes.override}
              autoFocus={autoFocus}
              severity={helperText ? 'Error' : 'Normal'}
              colorVariant={colorVariant}
              dropDownOpen={open}
              placeholder={placeholder}
              fieldSize={'fullWidth'}
              loading={loading}
              icon={
                <>
                  {props.value && !props.disableClearable ? (
                    <button className={classes.button} onClick={cancelOption} type='button'>
                      <IconHandler iconIdentifier='close' />
                    </button>
                  ) : allowCustomInputs ? (
                    <button className={classes.button} onClick={() => setOpen(!open)} type='button'>
                      <IconHandler iconIdentifier='search' />
                    </button>
                  ) : (
                    <button className={classes.button} onClick={() => setOpen(!open)} type='button'>
                      <IconHandler iconIdentifier='arrow' />
                    </button>
                  )}
                </>
              }
              {...rest}
            />
          </div>
        )}
      />
      {displayHelper && <HelperText id={props.id} helperText={helperText} />}
    </>
  );
}
export default FlsAutocomplete;
